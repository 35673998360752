@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');




*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top:75px;
}

body{
  font-weight:400;
  overflow-x:hidden;
  position:relative;
  background-color: #121212 !important;
  color:#fff !important;

}

h1, h2, h3, h4, h5, h6{
  margin:0;
  padding:0;
  line-height:normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}


/*Nav*/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  color: #ffffff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 22px;
  opacity: 0.75;
  font-weight:700;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 45px;
    height: 45px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 45px;
    height: 45px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

#Nav-logo{
  width:70%;
  height:70%;
}


/*Banner*/
.Banner-about {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./images/background3.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99;
}

.banner h1 {
  font-size: 80px;
  font-family: "Anta", sans-serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
  color:#121212;
  text-align: left;
}
.banner p {
  color: #333333;
  font-family: "Anta", sans-serif;
  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  text-align: left;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: FadeIn 0.5s linear;

}

@keyframes FadeIn {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    30%{
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.download-button{
  display: inline-block;
  padding: 8px 15px;
  color: rgba(19, 19, 19, 0.87);
  font-family: "Anta", sans-serif;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 30px;
}

.download-button {
  border: 10px solid transparent; /* 添加透明边框 */
  transition: all 0.3s ease; /* 平滑过渡效果 */
  box-shadow: none /* 创造悬浮的感觉 */
}

.download-button:hover {
  transform: translateY(-5px); /* 悬浮效果 */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.445); /* 加深阴影以增强悬浮效果 */
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.download-button img{
  width:40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.download-button:hover img {
  animation: bounce 1s infinite;
}






/* About */
.about {
  padding: 0 0 50px 0;
  position: relative;
  z-index: 1;
  font-family: "Anta", sans-serif;
}

.about-bx {
  background: #363636;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -20px;
  
}

.about-bx h2{
  font-size: 45px;
  margin-bottom: 30px;
}
.about-bx p{
  font-size: 18px;
  margin: auto 10vw;
}










/*Details*/
.detail{
  padding:80px 0;
  position: relative;
  background-color: white;
  z-index: 0;
  margin-top: -25vh;
}

.detail-content{
  margin-top: 8vh;
}

.detail h2{
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  color: black;
}
.Accordion{
  margin-top: 50px;
}

.accordion{
  margin-left: 15vw;
  margin-right: 15vw;
  border:0;
  border-radius: 0;
}

.accordion .item{
  border:0;
  border-radius: 0;
}

.accordion:last-child .item{
  border-top: 2px solid #d3d3d3;
  border-radius: 0;

}

.accordion-header{
  margin-left: 1vw;
}

.accordion-body{
  text-align:left;
  margin-left: 5vw;
}

.accordion .item .accordion-header button{
  background-color: white;
  box-shadow: none;
  font-size:1.5rem;
  letter-spacing:0.125rem;
  line-height:1.375rem;
  color:black;
  vertical-align:middle;
  position:relative;
  padding-bottom: 0;
  padding-top:0;
  height: 7vh;
}

.accordion .item .accordion-header button:after{
  transition: all .6s ease;
}

.accordion .item .accordion-collapse{
  transition: all .4s ease;
}


/*Project*/
.project {
  padding: 80px 0;
  position: relative;
  background-image: linear-gradient(90.21deg, #ca62fe -5.91%, #5d9eff 111.58%);
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 25%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #5342ec -5.91%, #3f0bfb 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-forth {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  /*background: linear-gradient(90.21deg, #5342ec -5.91%, #3f0bfb 111.58%);*/
  background:rgba(255, 125, 179, 0.671);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  user-select:none;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute; 
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

.proj-img{
  width: 100%;
  height: 100%;
}

/* Modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  z-index: 10004;
}

.modal-content-wrapper {
  position: fixed;
  width: 60%;
  height: 70vh;
  background: white;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10005;
  border-radius: 10px;
  
}

.modal-content{
  max-height:75%;
  overflow-y: overlay;
}


.img-with-mask img {
  max-width: 100%; /* 限制图片的最大宽度，使其不超过容器宽度 */
  max-height: 100%; /* 限制图片的最大高度，使其不超过视口高度 */
  display: block; /* 避免图片下方出现空隙 */
  object-fit: cover; /* 覆盖整个容器，可以根据需要调整为contain */
  position: absolute; /* 绝对定位，覆盖整个容器 */
  top: 0;
  left: 0;
  width: 100%; /* 宽度占满容器 */
  height: 100%; /* 高度占满容器 */
  border-radius: 10px 10px 0px 0px;
}

.img-with-mask {
  position: relative; /* 设置定位上下文 */
  max-width: 100%; /* 限制外层div的大小，可根据需要调整 */
  display: flex; /* 使用flex布局使内容居中 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 确保文本居中，对于较长的标题很有用 */
  height: 15vh;
}

.img-with-mask::after {
  content: ""; /* 伪元素内容为空 */
  position: absolute; /* 绝对定位，相对于.img-with-mask */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.40); /* 遮罩颜色和透明度 */
  z-index: 1;
  border-radius: 10px 10px 0px 0px;
}

.img-with-mask h1 {
  position: relative; /* 相对定位，确保位于遮罩之上 */
  z-index: 2; /* 保证标题位于遮罩层之上 */
  color: #fff; /* 标题颜色，根据需要调整 */
}


.modal-content-wrapper h1{
  color:rgb(255, 255, 255);
  font-weight: 700;
}


.project-modal {
  width: 50vw !important;
  margin: auto;
}

.modal-exit-button{
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
    color: #5a5a5a;
    border-radius: 4px;
}

.modal-exit-button {
  transition: transform 0.3s ease;
  display: inline-block; 
  transform: scale(0.6);
  z-index: 10008;
  pointer-events:auto !important;
}

.modal-exit-button:hover {
  transform: scale(0.9) rotate(90deg); 
}

.modal-exit-button img {
  display: block; 
  width: 100%; 
  height: auto; 
}




.project-content {
  position: relative;
  display: inline-block; 
  width: 100%; 
}
.project-content-containter{
  padding: 0px 0px 40px 0px ;
}

.project-content-text{
  color:#000;
}
.project-content-des p{
  color:#000;
  text-align: left;
}
.project-content-des h1{
  color: #000;
  font-size: 20px;
}

.project-content-text img{
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.37); 
}

.project-content-des{
  padding-top: 3%;
  margin:auto 5% !important;
}

.project-content-des p{
  margin:0 0 !important;
  width: 100%;
}
.project-content-containter img{
  width: 100%;
  height: auto;
  margin-top: 3%;
}

.project-content-containter iframe{
  width: 100%;
  height: 315;
  margin-top: 3%;
}


.project-content-des h3{
  margin-top: 20px !important;
  text-align: left;
  font-size: 20px;
}

.project-content-des .bullet-point {
  margin-top: 20px;
}






/* Contact */
.contact {
  background: white;
  padding: 60px 0 60px 0;
  /*background-image: url('./images/background.png');*/
  background-color: white;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
.contact img {
  width: 70%;
  border-radius: 20px;
  
}

.contact-image-container{
  text-align: center;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
  color:black;
  text-align: center;
}


.submitButton {
  border: 2px solid transparent; 
  transition: all 0.3s ease; 
  background-color: #ffffff; 
  color: #000000; 
  padding: 10px 50px; 
  cursor: pointer; 
  box-shadow: none; 
  font-size: 30px;
  border-radius: 10px;
  
}

.submitButton:hover {
  border-color: #fff; 
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.445); 
  transform: translateY(-2px); 
}



.contact form .submitButton img{
  border-radius: 0;
}

@keyframes slideAndDisappear {
  0% { transform: translateX(0); opacity: 1; }
  75% { transform: translateX(80px); opacity: 0; } 
  100% { transform: translateX(0); opacity: 0; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.sliding-image {
  animation: slideAndDisappear 2s forwards; 
}

.fading-in-image {
  animation: fadeIn 1s ease-out;
}




.button-container{
  text-align: center;
  margin-top: 20px;
}



.input-container{
  width:100%;
}

.entryarea{
  position:relative;
  height: 80px;
  line-height: 30px;
  padding: 0;
}

.entryarea input{
  position: absolute;
  width: 100%;
  outline:none;
  font-size: 1.5em;
  padding:10px 10px;
  line-height: 30px;
  border-radius: 10px;
  border:2px solid #000000;
  background: transparent;
  transition: 0.1s ease;
  z-index: 1111;
  text-align: left !important;
}

.labelline{
  position: absolute;
  font-size: 1.6em;
  color:#000000;
  padding:0 5px;
  margin: 0 20px;
  background-color: #ffffff;
  transition: 0.2s ease;
}

.entryarea input:focus,
.entryarea input:valid{
  color:#000000;
  border:4px solid #000000;

}
.entryarea input:focus + .labelline,
.entryarea input:valid + .labelline{
  color:#000000;
  height: 30px;
  line-height: 30px;
  padding:0 12px;
  transform: translate(-15px,-16px) scale(0.88);
  z-index: 1111;
}


.entryarea-me{
  position:relative;
  height: 25vh;
  line-height: 30px;
  padding: 0;
}

.entryarea-me textarea {
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 1.5em;
  padding: 10px 10px;
  border-radius: 10px;
  border: 2px solid #000000;
  background: transparent;
  transition: 0.1s ease;
  z-index: 1111;
  text-align: left;
  resize: none;
}

.entryarea-me input:focus,
.entryarea-me input:valid{
  color:#000000;
  border:4px solid #000000;

}


.entryarea-me textarea:focus + .labelline,
.entryarea-me textarea:valid + .labelline {
  color: #000000;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  transform: translate(-15px, -20px) scale(0.88); 
  z-index: 1111;
}





.email-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  z-index: 10004;
}

.email-modal-content-wrapper {
  position: fixed;
  width: 30vw;
  height: 30vh;
  background: white;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10005;
  border-radius:10px;
}

.email-img-with-mask img {
  max-width: 100%; 
  max-height: 100%; 
  display: block; 
  object-fit: cover; 
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  border-radius: 10px 10px 0 0;
}

.email-modal-content p{
  text-align: center;
  padding-top: 50px;
  font-size: larger;
  color:black;
}

.email-img-with-mask {
  position: relative; 
  max-width: 100%; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  height: 15vh;
}

.email-img-with-mask::after {
  content: ""; 
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1;
  border-radius: 10px 10px 0 0;
}

.email-img-with-mask h1 {
  position: relative; 
  z-index: 2; 
  color: #fff;
}


.email-modal-content-wrapper h1{
  color:rgb(255, 255, 255);
  font-weight: 700;
}

.email-modal-exit-button{
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
  color: #5a5a5a;
  border-radius: 4px;
}

.contact-button-img{
  width:20% !important;
  height: 20% !important;
}

.email-modal-exit-button {
transition: transform 0.3s ease;
display: inline-block; 
transform: scale(0.6);
z-index: 10008;
pointer-events:auto !important;
}

.email-modal-exit-button:hover {
transform: scale(0.9) rotate(90deg); 
}

.email-modal-exit-button img {
display: block; 
width: 100%; 
height: auto; 
}



/* Footer */
.footer {
  padding: 25px 0 10px 0;
  background-color: rgb(26, 26, 26);
}

.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #fafafa;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.social-icon-foot {
  display: inline-block;
  margin-left: 14px;
}
.social-icon-foot a {
  width: 50px;
  height: 50px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.social-icon-foot a::before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.social-icon-foot a:hover::before {
  transform: scale(1);
}
.social-icon-foot a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
}
.social-icon-foot a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}




.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar
{
 width: 10px;
 height: 16px;
 background:transparent;
 border-radius: 10px;
}

::-webkit-scrollbar-track
{
 -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
 border-radius: 10px;
 background-color: white;
 width: 10px;
}

::-webkit-scrollbar-thumb
{
 border-radius: 10px;
 -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
 background-color: #555;
}
:root{
  overflow-y: overlay;
}